import _path from "path";
import _globParent from "glob-parent";
import _isGlob from "is-glob";
var exports = {};
var path = _path;
var parent = _globParent;
var isGlob = _isGlob;

exports = function globBase(pattern) {
  if (typeof pattern !== "string") {
    throw new TypeError("glob-base expects a string.");
  }

  var res = {};
  res.base = parent(pattern);
  res.isGlob = isGlob(pattern);

  if (res.base !== ".") {
    res.glob = pattern.substr(res.base.length);

    if (res.glob.charAt(0) === "/") {
      res.glob = res.glob.substr(1);
    }
  } else {
    res.glob = pattern;
  }

  if (!res.isGlob) {
    res.base = dirname(pattern);
    res.glob = res.base !== "." ? pattern.substr(res.base.length) : pattern;
  }

  if (res.glob.substr(0, 2) === "./") {
    res.glob = res.glob.substr(2);
  }

  if (res.glob.charAt(0) === "/") {
    res.glob = res.glob.substr(1);
  }

  return res;
};

function dirname(glob) {
  if (glob.slice(-1) === "/") return glob;
  return path.dirname(glob);
}

export default exports;